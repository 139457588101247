@import './mixins.scss';
@import './variables.scss';

@mixin text-base {
  color: $TEXT_COLOR;

  &.border {
    border-bottom: 1px solid;
  }
  &.primary {
    color: $PRIMARY_COLOR;
  }
  &.white {
    color: #ffffff;
  }
  &.invalid {
    color: #eb1c26;
  }
  &.light {
    color: $LIGHT_TEXT_COLOR;
  }
  &.normal {
    font-weight: normal;
  }
  &.semi-bold {
    font-weight: 600;
  }
  &.bold {
    font-weight: 700;
  }
}

.text-small {
  font-size: 1rem;
  line-height: 1.2;
  @include text-base;
}

.text-normal {
  line-height: 1;
  font-size: 1.2rem;
  @include text-base;
}

.text-xl {
  line-height: 1.3;
  font-size: 1.4rem;
  @include text-base;
}

.text-2xl {
  line-height: 1.6;
  font-size: 1.7rem;
  @include text-base;
}

.text-3xl {
  font-size: 2rem;
  line-height: 1.6;
  @include text-base;
}

.text-5xl {
  font-size: 2.5rem;
  @include text-base;
}

.flex {
  display: flex;
  &.start {
    justify-content: start;
    align-items: flex-start;
  }
  &.center {
    align-items: center;
  }
  &.end {
    align-items: flex-end;
  }
  &.space-between {
    justify-content: space-between;
  }
  &.justify-center {
    justify-content: center;
  }
}

.pointer {
  cursor: pointer;
}

.circle-radius {
  border-radius: 50%;
}

.avatar {
  &.circle {
    border-radius: 50%;
  }
}

.btn {
  font-weight: 600;
  box-shadow: none !important;
  &.btn-lg {
    font-size: 1.4rem;
    border-radius: 6px;
    padding: 1rem 1.6rem;
  }
  &.btn-primary {
    color: #ffffff;
    background-color: #27ae60;
    &:hover {
      background-color: #1f8d4d;
    }
  }
  &.btn-outline-primary {
    color: #27ae60;
    border-color: #27ae60;
    &:hover {
      color: #ffffff;
      background-color: #27ae60;
    }
  }
  &:not(.btn-outline-primary) {
    border: 0;
  }
  .spinner-border {
    &.spinner-border-md {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
}

.card {
  border: 0;
  border-radius: 1rem;
  padding: 1.2rem 1.6rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
}

@media screen and (min-width: $DEFAULT_BREAKPOINT) {
  .card {
    border-radius: 1.6rem;
  }
}

.modal {
  z-index: 99999;
}

.modal.alert-modal {
  .modal-content {
    border: 0;
    border-radius: 6px;
    padding: 0.9rem 0 0 0;
    background: #ffffff;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    .modal-header {
      border-bottom: 0;
      padding: 0.9rem 1.8rem 1.8rem 1.8rem;
    }
    .modal-body {
      padding: 0 1.8rem 1.8rem 1.8rem;
    }
    .modal-footer {
      padding: 0.6rem 1.8rem;
      justify-content: space-between;
      border-top: 1px solid #eeeeee;
      .btn {
        flex: 1;
        margin: 0;
        border: 0;
        padding: 0.8rem 1.6rem;
      }
    }
  }
}

.form-control {
  border: 0;
  color: #000000;
  font-size: 1.4rem;
  border-radius: 0.8rem;
  background: #edeff2;
  margin-bottom: 1.2rem;
  padding: 1.2rem 1.8rem;
  ::placeholder {
    color: #979797;
    font-size: 1.4rem;
  }
  &:focus {
    color: #000000;
    box-shadow: none;
    border-color: #ced4da;
    background-color: #e0e3e7;
  }
  &.no-margin {
    margin-bottom: 0;
  }
}

.invalid-feedback {
  margin: -0.6rem 0 1.2rem 0;
}

.btn-group {
  padding: 0.4rem;
  border-radius: 4px;
  text-transform: uppercase;
  background-color: #fafaff;
  .btn {
    color: #c5c5c5;
    font-size: 1rem;
    min-width: 6rem;
    padding: 0.4rem 0.8rem;
    border-radius: 4px !important;
    background-color: #fafaff;
  }
  .btn-check:checked + .btn {
    color: #ffffff;
    background-color: #27ae60;
  }
}

a,
.text-link {
  color: #27ae60;
  cursor: pointer;
  font-size: 1.4rem;
  text-decoration: none;
}

@include list-loop('.pt-', 'padding-top');
@include list-loop('.mt-', 'margin-top');
@include list-loop('.pb-', 'padding-bottom');
@include list-loop('.mb-', 'margin-bottom');

.route-loader {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .spinner-border {
    width: 4rem;
    height: 4rem;
    border-width: 0.4rem;
  }
}

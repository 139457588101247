@import '~bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
@import './helpers.scss';

html {
  height: 100vh;
  width: 100vw;
  font-size: 62.5%;
  overflow: auto !important;
  font-family: 'Montserrat', sans-serif;
}

body {
  height: 100vh;
  width: 100vw;
  line-height: 1.7;
  font-weight: 500;
  font-size: 1.4rem;
  overflow: auto !important;
  background-color: #fafaff;
  font-family: 'Montserrat', sans-serif;
}

p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
label {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

:root {
  --bs-light-rgb: 255, 255, 255;
}

#root {
  height: 100vh;
  width: 100vw;
  .app-loader {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .spinner-border {
      width: 4rem;
      height: 4rem;
      border-width: 0.4rem;
    }
  }
}

.app-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 768px) {
  .app-wrapper {
    & > .app-container {
      max-width: $APP_MAX_WIDTH;
      margin: -3rem auto 0 auto;
      > div {
        border-radius: 6px;
        background-color: #ffffff;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
      }
    }
  }
}

.barber-profile {
  .app-web-header {
    padding: 2.4rem 0;
    > .barbr-space {
      padding: 0 4rem;
    }
  }
  .map-wrapper {
    position: relative;
    .bmap {
      border-radius: 1rem;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
      > div {
        > div {
          border-radius: 1rem;
        }
      }
    }
    .info {
      left: 4rem;
      right: 4rem;
      bottom: 2rem;
      border-radius: 6px;
      align-items: center;
      position: absolute;
      background-color: #ffffff;
      justify-content: space-between;
      padding: 0.4rem 0 0.4rem 1.8rem;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
      .barbr-space {
        padding: 0.4rem 0;
        align-items: center;
        .name {
          line-height: 1.3;
          text-transform: capitalize;
        }
      }
      .mark-icon {
        cursor: pointer;
        padding: 0.4rem 1.8rem;
        border-left: 1px solid #eeeeee;
      }
    }
  }
  .profile-body {
    margin: 0 0 0 0;
    margin-top: 59px;
    padding-bottom: 4rem;
    background-color: #ffffff;
    .container {
      max-width: 100%;
      padding: 0 1.6rem;
    }
    .basic-detail {
      .card {
        .about {
          margin-top: 11px;
          white-space: pre-line;
        }
      }
      .container {
        .barber-profile-meta-chip {
          padding: 1.8rem 0;
        }
        .referer-item {
          border-radius: 6px;
          margin-bottom: 2rem;
        }
        .section {
          padding: 1.8rem 0;
          border-bottom: 1px solid $BORDER_COLOR;
        }
      }
    }
    .profile-reviews {
      padding: 0;
    }
  }
  .profile-footer {
    background-color: #000000;
    padding: 2.8rem 2.4rem 5.2rem 2.4rem;
    * {
      color: #ffffff;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      li {
        margin: 1.6rem 0;
      }
    }
    .social-section {
      display: flex;
      justify-content: center;
      margin: 0.8rem 0 2.4rem 0;
    }
    .copyright {
      color: #adb5bd;
      text-align: center;
    }
  }
}

@media screen and (min-width: $DEFAULT_BREAKPOINT) {
  .barber-profile {
    .basic-detail {
      border-radius: 1rem;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
      .card {
        padding: 2rem;
        border-radius: 0 0 1rem 1rem;
        .shop,
        .about {
          margin-top: 1.6rem;
          padding-top: 1.6rem;
          border-top: 1px solid #e5e5e5;
          white-space: pre-line;
        }
      }
      .profile-cover .overlay {
        border-radius: 1rem 1rem 0 0;
      }
    }
    .profile-body {
      display: block;
      margin: 0 auto;
      max-width: 140rem;
      padding: 4rem 1.6rem;
      background-color: transparent;
      .container {
        padding: 0;
      }
      .profile-row {
        margin: 0 0 3.2rem 0;
        --bs-gutter-x: 4.8rem;
        &:first-child {
          align-items: center;
        }
      }
      .profile-barbers {
        margin: 0;
        .barber-item {
          background: #ffffff;
        }
      }
      .barber-profile-services {
        margin: 0;
        .service-item {
          background: #ffffff;
          padding: 0.8rem 0.6rem 0.6rem 1rem;
        }
      }
      .barber-profile-reviews .review-item {
        margin-bottom: 1.2rem;
      }
      .barber-profile-schedule-item {
        color: #000000;
        font-size: 1.4rem;
        padding: 0.4rem 0.8rem;
        &.today {
          color: $PRIMARY_COLOR;
        }
      }
    }
  }
}

.verification-tooltip {
  &.show {
    opacity: 1;
  }
  .tooltip-inner {
    display: flex;
    font-weight: 600;
    font-size: 1.2rem;
    border-radius: 5px;
    align-items: center;
    padding: 0.6rem 1.2rem;
    color: $LIGHT_TEXT_COLOR;
    background-color: #ffffff;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
  }
  .tooltip-arrow::before {
    border-top-color: #ffffff;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
  }
  img {
    width: 4rem;
    margin-left: 0.4rem;
  }
}

.auth-wrapper {
  position: relative;
  text-align: center;
  @include max-container;
  max-width: 48rem;
  padding: 10rem 1.6rem 3rem 1.6rem;
  .logo {
    margin-bottom: 1.2rem;
  }
  .cancel-icon {
    top: 5rem;
    cursor: pointer;
    position: absolute;
    border-radius: 50%;
    padding: 0.8rem 1rem;
    background-color: #ffffff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
  }
  .card {
    text-align: left;
    margin-top: 1.2rem;
    padding: 2rem 1.2rem;
    .row {
      --bs-gutter-x: 1.2rem;
    }
    .fs-link {
      right: 1rem;
      bottom: -1.8rem;
      position: absolute;
    }
  }
  .was-validated :invalid ~ .invalid-feedback {
    & + .fs-link {
      bottom: 1rem;
    }
  }
  .alert-danger {
    line-height: 1.4;
    font-size: 1.3rem;
    padding: 0.5rem 1rem;
    margin: 2.4rem 0 -1.8rem 0;
  }
}

.barber-profile-chip {
  img {
    border: 1px solid #dddddd;
    border-radius: 50%;
  }
}

.booking-callout {
  .new-address-card {
    padding-bottom: 2.8rem;
  }
  .existing-address-card {
    padding: 1.6rem;
    .barbr-space {
      cursor: pointer;
      padding: 1.6rem 0;
      border-bottom: 1px solid #eeeeee;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
      }
    }
  }
}

.payment-methods {
  .card {
    padding: 0.2rem 1.6rem;
    .payment-item {
      display: flex;
      padding: 2rem 0;
      border-bottom: 1px solid #eeeeee;
      .btn {
        padding: 0;
        font-weight: 600;
        color: #f00044;
        text-decoration: none;
        text-transform: uppercase;
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.no-promotion-empty-state {
  flex: 1;
  width: 32rem;
  display: flex;
  margin: 4rem auto;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.index-page {
  @include max-container;
  .header-overlay {
    background-color: #000000;
    padding: 4.8rem 2.4rem 15rem 2.4rem;
  }
  .card {
    padding: 2rem;
    margin: -12.5rem 2rem 0 2rem;
    .actions {
      .btn {
        flex: 1;
      }
    }
  }
}

.booking-cancel-confirmation-modal.modal {
  .modal-content .modal-footer {
    padding: 0.6rem 1.2rem;
    .btn {
      font-size: 1.3rem;
      padding: 0.8rem 1.2rem;
    }
  }
}

.not-found-page {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.basic-detail {
  .about {
    white-space: pre-line !important;
  }
}

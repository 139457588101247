@import './variables.scss';

@mixin list-loop($className, $styleName, $offset: 4, $start: 0, $max: 72, $divider: 1) {
  $i: $start;
  @while $i <=$max {
    #{$className + $i} {
      #{$styleName}: #{$i / $divider + 'px !important'};
    }
    $i: $i + $offset;
  }
}

@mixin max-container {
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: $APP_MAX_WIDTH;
}
